const industries = [
  { key: 'aerospace', label: 'Aerospace' },
  { key: 'agriculture', label: 'Agriculture' },
  { key: 'ats-and-culture', label: 'Arts and Culture' },
  { key: 'communication', label: 'Communication' },
  { key: 'construction', label: 'Construction' },
  { key: 'consulting', label: 'Consulting' },
  { key: 'consumer-goods', label: 'Consumer Goods' },
  { key: 'education', label: 'Education' },
  { key: 'electronics', label: 'Electronics' },
  { key: 'energy', label: 'Energy' },
  { key: 'entertainment', label: 'Entertainment' },
  { key: 'finance', label: 'Finance' },
  { key: 'food', label: 'Food' },
  { key: 'health-care', label: 'Health care' },
  { key: 'hospitality', label: 'Hospitality' },
  { key: 'it', label: 'IT' },
  { key: 'law', label: 'Law' },
  { key: 'manufacturing', label: 'Manufacturing' },
  { key: 'media', label: 'Media' },
  { key: 'mining', label: 'Mining' },
  { key: 'pharmaceuticals', label: 'Pharmaceuticals' },
  { key: 'real-estate', label: 'Real Estate' },
  { key: 'social-services', label: 'Social Services' },
  { key: 'telecommunications', label: 'Telecommunications' },
  { key: 'transport', label: 'Transport' },
  { key: 'wholesale-and-retail', label: 'Wholesale and Retail' },
  { key: 'other', label: 'Other' },
];

export default industries;
