import React from 'react';
import PropTypes from 'prop-types';
import { propTypes } from '../../util/types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureUser } from '../../util/data';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES, COMPANY_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.module.css';

const UserNavComponent = props => {
  const { className, rootClassName, selectedPageName, currentUser } = props;
  const classes = classNames(rootClassName || css.root, className);
  const user = ensureUser(currentUser);
  const isCompany =
    user && user.attributes.profile.publicData
      ? user.attributes.profile.publicData.isCompany
      : null;

  const tokenTabMaybe = !isCompany
    ? [
        {
          text: <FormattedMessage id="ManageListingsPage.tokens" />,
          selected: selectedPageName === 'TokensPage',
          linkProps: {
            name: 'TokensPage',
          },
        },
      ]
    : [];

  const companySettingsTabMaybe = isCompany
    ? [
        {
          text: <FormattedMessage id="ManageListingsPage.companySettings" />,
          selected: COMPANY_SETTINGS_PAGES.includes(selectedPageName),
          disabled: false,
          linkProps: {
            name: 'CompanyDetailsPage',
          },
        },
      ]
    : [];

  let tabs = [
    ...tokenTabMaybe,
    {
      text: <FormattedMessage id="ManageListingsPage.profileSettings" />,
      selected: selectedPageName === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="ManageListingsPage.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(selectedPageName),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
    ...companySettingsTabMaybe,
  ];

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
  currentUser: null,
};

const { string } = PropTypes;

UserNavComponent.propTypes = {
  className: string,
  rootClassName: string,
  selectedPageName: string.isRequired,
  currentUser: propTypes.currentUser,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  return { currentUser };
};

const UserNav = compose(connect(mapStateToProps))(UserNavComponent);

export default UserNav;
