import React from 'react';
import { propTypes } from '../../util/types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { IconSpinner, InlineTextButton, NamedLink } from '../../components';
import { LISTING_PAGE_PARAM_TYPE_EDIT, createSlug } from '../../util/urlHelpers';

import css from './ListingPage.module.css';

const JobActions = ({ currentListing, onCloseListing, closingListing, closingListingError }) => {
  const id = currentListing.id.uuid;
  const { title = '' } = currentListing.attributes;
  const slug = createSlug(title);

  const closingErrorListingId = !!closingListingError && closingListingError.listingId;

  return (
    <div className={classNames(css.jobActions, css.detailCard)}>
      <NamedLink
        className={css.jobAction}
        name="EditListingPage"
        params={{ id, slug, type: LISTING_PAGE_PARAM_TYPE_EDIT, tab: 'general-information' }}
      >
        <span className={css.jobActionBorder}></span>
        <FormattedMessage id="TransactionPanel.editJob" />
      </NamedLink>
      {closingListing?.uuid === id ? (
        <IconSpinner />
      ) : (
        <InlineTextButton
          onClick={() => onCloseListing(currentListing.id)}
          className={classNames(css.jobAction, css.closeJob)}
        >
          <span className={css.jobActionBorder}></span>
          <FormattedMessage id="TransactionPanel.closeJob" />
        </InlineTextButton>
      )}
      {closingErrorListingId.uuid === id && (
        <p className={css.errorText}>{closingListingError?.error?.message}</p>
      )}
    </div>
  );
};

JobActions.propTypes = {
  currentListing: propTypes.listing.isRequired,
};

export default JobActions;
