import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSocialMediaLinkedin.module.css';

const IconSocialMediaLinkedin = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      width="100%"
      height="100%"
      viewBox="0 0 448 448"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M416,0l-384.1,0c-17.6,0 -31.9,14.5 -31.9,32.3l0,383.4c0,17.8 14.3,32.3 31.9,32.3l384.1,-0c17.6,-0 32,-14.5 32,-32.3l0,-383.4c0,-17.8 -14.4,-32.3 -32,-32.3Zm-280.5,384l-66.5,0l0,-213.8l66.5,0l0,213.8Zm-33.3,-243c-21.3,0 -38.5,-17.3 -38.5,-38.5c0,-21.2 17.2,-38.5 38.5,-38.5c21.2,0 38.5,17.3 38.5,38.5c0,21.3 -17.2,38.5 -38.5,38.5Zm282.1,243l-66.4,0l0,-104c0,-24.8 -0.5,-56.7 -34.5,-56.7c-34.6,0 -39.9,27 -39.9,54.9l0,105.8l-66.4,0l0,-213.8l63.7,0l0,29.2l0.9,0c8.9,-16.8 30.6,-34.5 62.9,-34.5c67.2,0 79.7,44.3 79.7,101.9l0,117.2Z" />
    </svg>
  );
};

IconSocialMediaLinkedin.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

IconSocialMediaLinkedin.propTypes = { rootClassName: string, className: string };

export default IconSocialMediaLinkedin;
