const categories = [
  { key: 'administration', label: 'Administration' },
  { key: 'architecture', label: 'Architecture' },
  { key: 'business-development', label: 'Business Development' },
  { key: 'communications', label: 'Communications' },
  { key: 'consulting', label: 'Consulting' },
  { key: 'finance', label: 'Finance' },
  { key: 'hr', label: 'HR' },
  { key: 'industrial-engineering', label: 'Industrial Engineering' },
  { key: 'it', label: 'IT' },
  { key: 'law', label: 'Law' },
  { key: 'logistics', label: 'Logistics' },
  { key: 'management', label: 'Management' },
  { key: 'marketing', label: 'Marketing' },
  { key: 'medical', label: 'Medical' },
  { key: 'operations', label: 'Operations' },
  { key: 'sales', label: 'Sales' },
  { key: 'science', label: 'Science' },
  { key: 'other', label: 'Other' },
];

export default categories;
