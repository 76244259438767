import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { NamedLink } from '../../components';
import industries from '../../config/industries.js';
import categories from '../../config/categories.js';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const truncate = (str, max, suffix) =>
  str.length < max
    ? str
    : `${str.substr(0, str.substr(0, max - suffix.length).lastIndexOf(' '))}${suffix}`;

export const ListingCardComponent = props => {
  const { className, rootClassName, listing } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', description, publicData } = currentListing.attributes;
  const slug = createSlug(title);

  const industry = publicData
    ? industries.find(item => item.key === publicData.industry)?.label
    : null;
  const category = publicData
    ? categories.find(item => item.key === publicData.category)?.label
    : null;
  const location = publicData?.location?.address;
  const positionsNumber = publicData?.positionsNumber;

  const richTitle = richText(title, {
    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
    longWordClass: css.longWord,
  });

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div className={css.info}>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {positionsNumber ? (
              <FormattedMessage
                id="ListingCard.titleWithPositions"
                values={{ title: richTitle, count: positionsNumber }}
              />
            ) : (
              richTitle
            )}
          </div>
          <div className={css.secondaryInfo}>
            {industry && (
              <p className={css.secondaryInfoLine}>
                <FormattedMessage id="ListingCard.industry" />
                <span>{industry}</span>
              </p>
            )}
            {category && (
              <p className={css.secondaryInfoLine}>
                <FormattedMessage id="ListingCard.category" />
                <span>{category}</span>
              </p>
            )}
            {location && (
              <p className={classNames(css.secondaryInfoLine, css.location)}>
                <FormattedMessage id="ListingCard.location" />
                <span>{location}</span>
              </p>
            )}
          </div>
          {description && (
            <div className={css.description}>
              <p>{truncate(description, 130, '[…]')}</p>
            </div>
          )}
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  listing: propTypes.listing.isRequired,
};

export default ListingCardComponent;
