import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: May, 31th 2022</p>

      <ol>
        <li>
          <h3>General</h3>

          <p>
            This Privacy Policy describes how RecHunt Oy (“we,” “us,” or “RecHunt”) processes your personal data. The notice applies when you access and use our platform (the “Service”) via websites or otherwise interact with us in connection with the Service. RecHunt’s Service is an internet marketplace connecting natural persons and companies providing headhunting and recruiting services (“Recruiters) and companies seeking to engage a recruiting service to fulfil a position of employment (“Hiring Companies”) (together “you” or “Users”).
          </p>
          <p>
            The Service provided by RecHunt is governed by this Privacy Policy and Terms of Service. In case you do not find some relevant information from this Privacy Policy, please see our Terms of Service. All provisions, definitions and clarifications not provided herein, but provided in the Terms of Service shall be applied as provided therein.
          </p>
          <p>
            By using or accessing the Service you give consent to the processing, use and disclosure of your personal data. If you do not agree to any part of this Privacy Policy, we cannot provide the Service to you, and you should stop accessing and using the Service and deactivate your possible User Account.
          </p>
          <p>
            Please note that this Privacy Policy may be amended from time to time. RecHunt shall notify the Users of amendments to the Privacy Policy within a reasonable time before the amended Terms shall become applicable. If you disagree with the revised Privacy Policy, you can cancel your User Account.
          </p>
        </li>

        <li>
          <h3>What data do we process?</h3>

          <h3>2.1. Personal data you provide when using our Service or creating a RecHunt account</h3>

          <p>
            RecHunt collects various types of personal data which is provided by you to us. This includes:
          </p>
          <p>
            <b>User Account Information</b>. When signing up for the Service, we require you to provide account information which is necessary for the adequate performance of the agreement between you and RecHunt. This information includes your or your employee representative’s first name, last name, phone number, email address and a link to the corresponding LinkedIn profile.
          </p>
          <p>
            <b>Information required for using features of the Service or which you choose to provide</b>. In addition, it is mandatory or optional, depending on the case, that you provide additional information to use certain features of our Service. After signing up for the Service, you may choose to provide additional personal data, such as your work and educational history, to the Service in order to build up your profile. By default, the Recruiters’ profiles are not publicly visible, and only visible to the Hiring Companies to which the Recruiter has submitted its proposal.
          </p>
          <p>
            On the other hand, when you choose to make proposals as a recruiter or post Open Jobs as a hiring company, the provision of certain information is mandatory to use these essential features of our Service. This information is required to allow the Recruiters and Hiring Companies to find a suitable partner in a given recruitment process. When you are providing information, we inform you whether information is mandatory for using a respective feature of the Service.
          </p>
          <p>
            <b>Payment transaction information</b>. You as a Recruiter may also opt for purchasing tokens in order to offer your services to the Hiring Companies. In this case, we require you to provide certain financial information (like your credit card information) to facilitate the processing of payments. The payment service is arranged by us through a third-party payment service provider, which applies its own terms for processing the payments. We also collect payment transaction information necessary for invoicing the Recruiter on the basis of Commission.
          </p>

          <h3>2.2. Personal data automatically collected by using our Service</h3>

          <p>
            When you use our Service, we automatically collect personal data. This includes:
          </p>
          <p>
            <b>Usage Information</b>. Such as the pages or content you view, proposal you have made and other actions you have taken when using the Service.
          </p>
          <p>
            <b>Log Data and Device Information</b>. Such as details about how you’ve used the Service (including if you clicked on links to third party applications), IP address, access dates and times, hardware and software information, device information, device event information, unique identifiers, crash data, cookie data, and the pages you’ve viewed or engaged with before or after using the Service. We collect this information even if you haven’t created a RecHunt account or logged in, although most of the content in our website requires signing up for the Service.
          </p>


          <h3>2.3. Personal data from other sources</h3>

          <p>
            It is possible that the Users submit to our Service personal data of third parties. For instance, the Hiring Companies may provide contact details of persons responsible for or otherwise involved in the recruitment process. However, RecHunt does not generally require that any personal data relating to third parties be submitted in order to use the Service. Especially, RecHunt does not process personal data of the persons participating in the recruitment process as candidates for the Open Jobs, nor it is aware of which person will be eventually hired as a result of the recruitment process. This type of processing occurs outside the Service and shall be agreed upon as between the Hiring Company and the Recruiter.
          </p>
        </li>

        <li>
          <h2>For which purposes we process your personal data?</h2>

          <p>
            We use, store and process information, including personal data, about you for the following purposes:
          </p>

          <ol type="a">
            <li>
              <p>
                To enable you to use and access the Service.
              </p>
            </li>
            <li>
              <p>
                To enable your communication with other Users.
              </p>
            </li>
            <li>
              <p>
                To operate, protect, improve, and optimize the Service, the user experience and our business operations, for which we may inter alia perform analytics, conduct research, personalize or otherwise customize your experience.
              </p>
            </li>
            <li>
              <p>
                To provide you customer service and to send you messages, reminders, technical notices, updates and security alerts related to the Service, and information requested by you.
              </p>
            </li>
            <li>
              <p>
                To help create and maintain a data secure and data protected environment in the Service, including performing risk assessments and actions for preventing and reject actual and potential misconduct, malpractice, assaults and other unwanted activities which may cause any damage to you or us.
              </p>
            </li>
            <li>
              <p>
                To send you marketing, advertising, and promotional messages and other information that we consider being of interest to you, including information about us, our campaigns and our Service. You can unsubscribe or opt-out from receiving these communications by contacting us.
              </p>
            </li>
            <li>
              <p>
                To comply with our legal obligations, resolve any disputes that we may have with any of our users, and enforce our agreements with third parties.
              </p>
            </li>
          </ol>
        </li>

        <li>
          <h3> Legal bases for processing the data</h3>

          <p>
            Our legal basis for processing varies depending on the specific purpose for which we use personal data. We rely on:
          </p>
          <p>
            <b>Performance of a contract</b> when we provide you with Service, or communicate with you about it. This includes collecting information from you necessary for signing up for the Service, purchasing tokens, invoicing, using features of our Service, or communication with you on technical, security-related and contractually relevant subjects (e.g. fraud warnings or account blocking).
          </p>
          <p>
            <b>Our legitimate business interests and the interests of our customers</b> when we improve our Service, personalize our Service, when we detect and prevent fraud and abuse in order to protect the security of our Users, ourselves, or others, and when we provide you with interest-based advertising or recommendations.
          </p>
          <p>
            <b>Your consent</b> when we ask for your consent to process your personal data for a specific purpose that we communicate to you. For instance, we ask your consent for participation in surveys. When you consent to our processing your personal data for a specified purpose, you may withdraw your consent at any time and we will stop processing of your data for that purpose.
          </p>
          <p>
            <b>Compliance with a legal obligation</b> when we use your personal data to comply with laws. For example, we may keep information for tax, legal reporting, and auditing obligations.
          </p>
        </li>

        <li>
          <h3>How long do we store the personal data?</h3>

          <p>
            We retain your personal data to enable your continued use of Service, for as long as it is required in order to fulfil the relevant purposes described in this Privacy Policy, as may be required by law such as for tax and accounting purposes, or as otherwise communicated to you. Personal data you provide to our Service is usually processed on the basis of a contractual relationship and stored, as a rule, for the duration of the contractual relationship or as long as the provision of the Service requires. For example, if you are using the Service as a Recruiter, we retain your transaction history and information on your proposals so that you can review your past activities and we can resolve any potential dispute or claim afterwards. We delete or de-identify personal data when it is no longer necessary for the purposes it was collected for.  When you delete your account, your personal data will generally be deleted within 90 days. However, some personal data, e.g. reviews and transaction, are retained longer to the extent necessary to safeguard the interests of other Users and our legitimate business interests, especially in protecting the integrity and security of our Service and other Users.
          </p>
        </li>

        <li>
          <h3>To whom do we disclose your personal data?</h3>

          <p>
            <b>Authorized service providers</b>: We use third party service providers when providing our Service. In particular, we use service providers to provide the marketplace software and the underlying infrastructure for our Service, and payment service providers. To the extent that the service providers need access to personal data in order for us and such third parties to perform the Service, we provide such third parties with your data.
          </p>
          <p>
            When data is processed our authorized service providers on behalf of RecHunt, RecHunt has taken the appropriate contractual and organizational measures to ensure that your data are processed exclusively for the purposes specified in this Privacy Policy, in accordance with data protection laws, and subject to our instructions and obligations of confidentiality and appropriate security measures.
          </p>
          <p>
            <b>Mergers and acquisitions</b>: If we decide to sell, merge or otherwise reorganize our businesses, this may involve us disclosing personal data to prospective or actual purchasers and their advisers.
          </p>
          <p>
            <b>Authorities, legal proceedings and law</b>: We will disclose your data to competent authorities, such as the law enforcement authorities, to the extent required by law. We may also disclose your personal data in relation to legal proceedings on the basis of applicable law, or as otherwise required by law.
          </p>
        </li>

        <li>
          <h3>Transfers of personal data</h3>

          <p>
            We store your personal data within the European Economic Area. However, our service providers may transfer personal data to third countries when this is necessary for the successful provision of our Service. In such cases, we will take steps to ensure that your personal data receives an adequate level of protection in the jurisdictions in which they are processed. We provide adequate protection for the transfers of personal data to countries outside of the European Economic Area by ensuring that the transfers are based on the standard contractual clauses and, if necessary, supplementary safeguards.
          </p>
        </li>

        <li>
          <h3>Cookies</h3>

          <p>
            We only use cookies for user authentication and payment purposes. The cookies we use are strictly necessary cookies without which we would be unable to offer our Service. These strictly necessary cookies are automatically placed on your computer or device when you access our website or take certain actions on our website.
          </p>
        </li>

        <li>
          <h3>What are your rights as a data subject?</h3>

          <p>
            <b>The right of access</b>. You have the right to receive a confirmation of whether your personal data are processed, and if they are, to gain access to the data.
          </p>
          <p>
            <b>The right to give and withdraw your consent</b>. If the processing of your personal data is based on your consent, you have the right to withdraw your consent at any time.
          </p>
          <p>
            <b>The right to rectify data</b>. You are entitled to have your personal data rectified or, in certain cases, to have defective personal data supplemented. Please note that, where applicable, you may review, update, correct or delete information that you have provided by logging to your User Account.
          </p>
          <p>
            <b>The right to object to the processing of personal data</b>. You are entitled to object to the processing of your personal data based on RecHunt’s legitimate interests. RecHunt may reject the request, if the processing is necessary in order to implement RecHunt’s compelling legitimate interests. You are always entitled to oppose to the processing of your personal data for direct marketing purposes.
          </p>
          <p>
            <b>The right to data portability</b>. You have the right to receive your personal data you have submitted to us for processing based on your consent or performance of contract. You are entitled to receive the data in a structured, commonly used and machine-readable format, and the right to transfer the data to another controller.
          </p>
          <p>
            <b>The right to be forgotten</b>. You are entitled to ask RecHunt to erase data related to you, for example, if (i) you consider them unnecessary for the purposes described above, (ii) you cancel the consent you have given, (iii) you consider RecHunt to process your personal data contrary to law, or (iv) you object to the use of your personal data for direct marketing purposes. Please remember that in such case the lost information may not be brought back.
          </p>
          <p>
            <b>The right to restriction of processing</b>. You have the right under certain circumstances to require the controller to restrict the processing of your personal data.
          </p>
        </li>

        <li>
          <h3>How you can contact us?</h3>

          <p>
            In case you have any questions or complaint about this Privacy Policy or RecHunt’s handling of personal data or wish to exercise your above-mentioned rights as a data subject, please contact us at privacy@rechunt.com or send us a notice to the address below.
          </p>
          <p>
            <b>Controller</b>
          </p>
          <p>
            RecHunt Oy
          </p>
          <p>
            Eteläinen Hesperiankatu 16 B 33
          </p>
          <p>
            00100 Helsinki
          </p>
          <p>
            Finland
          </p>
          <p>
            If you think that RecHunt has acted contrary to the Privacy Policy or applicable data protection laws, you can also file a complaint with your national data protection authority, which monitors the lawfulness of the processing of personal data.
          </p>
        </li>
      </ol>
    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
