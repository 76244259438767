import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconWallet.module.css';

const IconWallet = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      width="100%"
      height="100%"
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M18.357,17.774c-1.198,-0 -2.173,-0.975 -2.173,-2.173l0,-3.608c0,-1.198 0.975,-2.172 2.173,-2.172l5.661,-0c0.034,-0 0.068,0.001 0.102,0.002l0,-3.594c0,-0.925 -0.75,-1.675 -1.675,-1.675l-20.77,-0c-0.925,-0 -1.675,0.75 -1.675,1.675l-0,15.136c-0,0.926 0.75,1.676 1.675,1.676l20.77,-0c0.925,-0 1.675,-0.75 1.675,-1.676l0,-3.594c-0.034,0.002 -0.068,0.003 -0.102,0.003l-5.661,-0Z"
          fill="#4a4a4a"
        />
        <path
          d="M24.018,11.011l-5.661,0c-0.543,0 -0.982,0.44 -0.982,0.982l-0,3.608c-0,0.543 0.439,0.982 0.982,0.982l5.661,0c0.542,0 0.982,-0.439 0.982,-0.982l0,-3.608c0,-0.542 -0.44,-0.982 -0.982,-0.982Zm-3.71,4.204c-0.783,-0 -1.418,-0.635 -1.418,-1.418c0,-0.783 0.635,-1.417 1.418,-1.417c0.782,-0 1.417,0.634 1.417,1.417c0,0.783 -0.635,1.418 -1.417,1.418Z"
          fill="#4a4a4a"
        />
        <path
          d="M19.528,1.485c-0.375,-1.134 -1.598,-1.75 -2.732,-1.375l-8.511,2.811l11.718,0l-0.475,-1.436Z"
          fill="#4a4a4a"
        />
      </g>
    </svg>
  );
};

IconWallet.defaultProps = {
  rootClassName: null,
  classNames: null,
};

IconWallet.propTypes = {
  rootClassName: string,
  classNames: string,
};

export default IconWallet;
