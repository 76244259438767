const employees = [
  { key: 'under-10', label: 'Under 10' },
  { key: '10-50', label: '10-50' },
  { key: '50-200', label: '50-200' },
  { key: '200-500', label: '200-500' },
  { key: '500-1000', label: '500-1000' },
  { key: 'over-1000', label: 'Over 1000' },
];

export default employees;
