import React from 'react';
import { arrayOf, bool, number, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';

import config from '../../config';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  txIsProposed,
  txIsReviewedByCustomer,
  txIsReviewedByProvider,
  txIsReviewed,
  txIsRecruiterShortlisted,
  txIsDeclined,
  txIsRecruiterSelected,
  txIsCandidatesShortlistAccepted,
  txIsCandidateHired,
  txCandidateHasBeenShortlisted,
} from '../../util/transaction';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { formatDateIntoPartials } from '../../util/dates';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  Avatar,
  NamedLink,
  Page,
  PaginationLinks,
  TabNav,
  LayoutSideNavigation,
  LayoutWrapperMain,
  LayoutWrapperSideNav,
  LayoutWrapperTopbar,
  LayoutWrapperFooter,
  Footer,
  IconSpinner,
  UserDisplayName,
} from '../../components';

import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import NotFoundPage from '../../containers/NotFoundPage/NotFoundPage';

import css from './InboxPage.module.css';

// Translated name of the state of the given transaction
export const txState = (intl, tx, type) => {
  const isOrder = type === 'order';

  if (txIsProposed(tx)) {
    return {
      lastTransitionedAtClassName: css.lastTransitionedAtEmphasized,
      stateClassName: isOrder ? css.stateNoActionNeeded : css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateProposed',
      }),
    };
  } else if (txIsRecruiterShortlisted(tx)) {
    return {
      stateClassName: isOrder ? css.stateNoActionNeeded : css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateShortlisted',
      }),
    };
  } else if (txIsDeclined(tx)) {
    return {
      stateClassName: css.stateConcluded,
      state: intl.formatMessage({
        id: 'InboxPage.stateDeclined',
      }),
    };
  } else if (txIsRecruiterSelected(tx)) {
    return {
      stateClassName: isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateRecruiterSelected',
      }),
    };
  } else if (txIsCandidatesShortlistAccepted(tx)) {
    return {
      stateClassName: isOrder ? css.stateActionNoNeeded : css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateCandidatesShortlistAccepted',
      }),
    };
  } else if (txIsCandidateHired(tx)) {
    return {
      stateClassName: css.stateActionNeeded,
      state: intl.formatMessage({
        id: 'InboxPage.stateCandidateHired',
      }),
    };
  } else if (txIsReviewedByCustomer(tx)) {
    const translationKey = isOrder ? 'InboxPage.stateReviewGiven' : 'InboxPage.stateReviewNeeded';
    return {
      stateClassName: isOrder ? css.stateNoActionNeeded : css.stateActionNeeded,
      state: intl.formatMessage({
        id: translationKey,
      }),
    };
  } else if (txIsReviewedByProvider(tx)) {
    const translationKey = isOrder ? 'InboxPage.stateReviewNeeded' : 'InboxPage.stateReviewGiven';
    return {
      stateClassName: isOrder ? css.stateActionNeeded : css.stateNoActionNeeded,
      state: intl.formatMessage({
        id: translationKey,
      }),
    };
  } else if (txIsReviewed(tx)) {
    return {
      stateClassName: css.stateConcluded,
      state: intl.formatMessage({
        id: 'InboxPage.stateReviewed',
      }),
    };
  } else {
    console.warn('This transition is unknown:', tx.attributes.lastTransition);
    return null;
  }
};

export const InboxItem = props => {
  const { type, tx, intl, stateData } = props;
  const { customer, provider, listing } = tx;
  const isOrder = type === 'order';

  const otherUser = isOrder ? provider : customer;
  const otherUserDisplayName = <UserDisplayName user={otherUser} intl={intl} />;
  const isOtherUserBanned = otherUser.attributes.banned;

  const isSaleNotification = !isOrder && txIsProposed(tx);
  const rowNotificationDot = isSaleNotification ? <div className={css.notificationDot} /> : null;
  const lastTransitionedAt = formatDateIntoPartials(tx.attributes.lastTransitionedAt, intl);

  const linkClasses = classNames(css.itemLink, {
    [css.bannedUserLink]: isOtherUserBanned,
  });

  const shouldHideProvider = !txCandidateHasBeenShortlisted(tx);

  return (
    <div className={css.item}>
      <div className={css.itemAvatar}>
        <Avatar user={shouldHideProvider ? null : otherUser} />
      </div>
      <NamedLink className={linkClasses} name="OrderDetailsPage" params={{ id: tx.id.uuid }}>
        <div className={css.rowNotificationDot}>{rowNotificationDot}</div>
        <div className={css.itemInfo}>
          {!shouldHideProvider ? (
            <>
              <div className={css.itemUsername}>{otherUserDisplayName}</div>
              <div className={css.itemOrderInfo}>
                <span>{listing?.attributes?.title}</span>
                <br />
              </div>
            </>
          ) : (
            <div className={css.itemUsername}>{listing?.attributes?.title}</div>
          )}
        </div>
        <div className={css.itemState}>
          <div className={classNames(css.stateName, stateData.stateClassName)}>
            {stateData.state}
          </div>
          <div
            className={classNames(css.lastTransitionedAt, stateData.lastTransitionedAtClassName)}
            title={lastTransitionedAt.dateAndTime}
          >
            {lastTransitionedAt.date}
          </div>
        </div>
      </NamedLink>
    </div>
  );
};

InboxItem.propTypes = {
  unitType: propTypes.lineItemUnitType.isRequired,
  type: oneOf(['order', 'sale']).isRequired,
  tx: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
};

export const InboxPageComponent = props => {
  const {
    unitType,
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    intl,
    pagination,
    params,
    scrollingDisabled,
    transactions,
  } = props;
  const { tab } = params;
  const ensuredCurrentUser = ensureCurrentUser(currentUser);

  const validTab = ['open', 'shortlisted', 'ongoing', 'completed'].includes(tab);
  if (!validTab) {
    return <NotFoundPage />;
  }

  const title = intl.formatMessage({ id: 'InboxPage.ordersTitle' });

  const toTxItem = tx => {
    const type = 'order';
    const stateData = txState(intl, tx, type);

    // Render InboxItem only if the latest transition of the transaction is handled in the `txState` function.
    return stateData ? (
      <li key={tx.id.uuid} className={css.listItem}>
        <InboxItem unitType={unitType} type={type} tx={tx} intl={intl} stateData={stateData} />
      </li>
    ) : null;
  };

  const error = fetchOrdersOrSalesError ? (
    <p className={css.error}>
      <FormattedMessage id="InboxPage.fetchFailed" />
    </p>
  ) : null;

  const noResults =
    !fetchInProgress && transactions.length === 0 && !fetchOrdersOrSalesError ? (
      <li key="noResults" className={css.noResults}>
        <FormattedMessage id="InboxPage.noOrdersFound" />
      </li>
    ) : null;

  const hasOrderTransactions = (tx, user) => {
    return user.id && tx && tx.length > 0 && tx[0].customer.id.uuid === user.id.uuid;
  };
  const hasTransactions =
    !fetchInProgress && hasOrderTransactions(transactions, ensuredCurrentUser);
  const pagingLinks =
    hasTransactions && pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="InboxPage"
        pagePathParams={params}
        pagination={pagination}
      />
    ) : null;

  const tabs = [
    {
      text: (
        <span>
          <FormattedMessage id="InboxPage.tab.open" />
        </span>
      ),
      selected: tab === 'open',
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'open' },
      },
    },
    {
      text: (
        <span>
          <FormattedMessage id="InboxPage.tab.shortlisted" />
        </span>
      ),
      selected: tab === 'shortlisted',
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'shortlisted' },
      },
    },
    {
      text: (
        <span>
          <FormattedMessage id="InboxPage.tab.ongoing" />
        </span>
      ),
      selected: tab === 'ongoing',
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'ongoing' },
      },
    },
    {
      text: (
        <span>
          <FormattedMessage id="InboxPage.tab.completed" />
        </span>
      ),
      selected: tab === 'completed',
      linkProps: {
        name: 'InboxPage',
        params: { tab: 'completed' },
      },
    },
  ];
  const nav = <TabNav rootClassName={css.tabs} tabRootClassName={css.tab} tabs={tabs} />;

  const sectionTitle = `InboxPage.title.recruiter.${params.tab}`;
  const sectionDescription = `InboxPage.description.recruiter.${params.tab}`;

  const descr = (
    <div className={css.inboxTabTitle}>
      <h2>
        <FormattedMessage className={css.paddingBottom} id={sectionTitle} />
      </h2>
      <h4>
        <FormattedMessage className={css.paddingBottom} id={sectionDescription} />
      </h4>
    </div>
  );

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSideNavigation>
        <LayoutWrapperTopbar>
          <TopbarContainer
            className={css.topbar}
            mobileRootClassName={css.mobileTopbar}
            desktopClassName={css.desktopTopbar}
            currentPage="InboxPage"
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperSideNav className={css.navigation}>
          <h1 className={css.title}>
            <FormattedMessage id="InboxPage.title" />
          </h1>
          {nav}
        </LayoutWrapperSideNav>
        <LayoutWrapperMain>
          {descr}
          {error}
          <ul className={css.itemList}>
            {!fetchInProgress ? (
              transactions.map(toTxItem)
            ) : (
              <li className={css.listItemsLoading}>
                <IconSpinner />
              </li>
            )}
            {noResults}
          </ul>
          {pagingLinks}
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSideNavigation>
    </Page>
  );
};

InboxPageComponent.defaultProps = {
  unitType: config.lineItemUnitType,
  currentUser: null,
  currentUserHasOrders: null,
  fetchOrdersOrSalesError: null,
  pagination: null,
  providerNotificationCount: 0,
  sendVerificationEmailError: null,
};

InboxPageComponent.propTypes = {
  params: shape({
    tab: string.isRequired,
  }).isRequired,

  unitType: propTypes.lineItemUnitType,
  currentUser: propTypes.currentUser,
  fetchInProgress: bool.isRequired,
  fetchOrdersOrSalesError: propTypes.error,
  pagination: propTypes.pagination,
  providerNotificationCount: number,
  scrollingDisabled: bool.isRequired,
  transactions: arrayOf(propTypes.transaction).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { fetchInProgress, fetchOrdersOrSalesError, pagination, transactionRefs } = state.InboxPage;
  const { currentUser, currentUserNotificationCount: providerNotificationCount } = state.user;
  return {
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    providerNotificationCount,
    scrollingDisabled: isScrollingDisabled(state),
    transactions: getMarketplaceEntities(state, transactionRefs),
  };
};

const InboxPage = compose(
  connect(mapStateToProps),
  injectIntl
)(InboxPageComponent);

export default InboxPage;
