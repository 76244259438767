import React from 'react';
import classNames from 'classnames';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';

import { NamedLink } from '../../components';

import css from './Checkbox.module.css';

const CheckboxComponent = props => {
  const {
    rootClassName,
    className,
    textClassName,
    id,
    isShortlisted,
    checkTermsOfTheService,
  } = props;

  const classes = classNames(rootClassName || css.root, className);

  const termsLink = (
    <NamedLink className={css.modalHelperLink} name="TermsOfServicePage">
      <FormattedMessage id="TransactionPanel.termsLink" />
    </NamedLink>
  );

  return (
    <span className={classes}>
      <label className={css.label}>
        <input
          type="checkbox"
          id={id}
          className={css.input}
          onChange={event => checkTermsOfTheService(event)}
        />
        <span className={classNames(css.text, textClassName || css.textRoot)}>
          {isShortlisted ? (
            <FormattedMessage
              id="TransactionPanel.termsOfTheServiceShortlistedLabel"
              values={{ termsLink }}
            />
          ) : (
            <FormattedMessage
              id="TransactionPanel.termsOfTheServiceSelectedLabel"
              values={{ termsLink }}
            />
          )}
        </span>
      </label>
    </span>
  );
};

CheckboxComponent.defaultProps = {
  className: null,
  rootClassName: null,
  svgClassName: null,
  textClassName: null,
};

CheckboxComponent.propTypes = {
  className: string,
  rootClassName: string,
  svgClassName: string,
  textClassName: string,

  // Id is needed to connect the label with input.
  id: string.isRequired,

  // Name groups several checkboxes to an array of selected values
  name: string.isRequired,

  // Checkbox needs a value that is passed forward when user checks the checkbox
  value: string.isRequired,
};

export default CheckboxComponent;
