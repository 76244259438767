import React from 'react';
import classNames from 'classnames';
import { bool, string, object } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';

import css from './JobProgressBar.module.css';

const JobProgressBarStep = ({ completed, label, current }) => {
  const classes = classNames(css.progressBarStepMarker, {
    [css.progressBarMarkerCompleted]: completed,
    [css.progressBarMarkerCurrent]: current,
  });
  const rootClasses = classNames(css.progressBarStep, {
    [css.progressBarStepCompleted]: completed,
    [css.progressBarStepCurrent]: current,
  });
  return (
    <div className={rootClasses}>
      <div className={classes}></div>
      <div className={css.progressBarStepTitle}>
        <FormattedMessage id={label} />
      </div>
    </div>
  );
};

JobProgressBarStep.defaultProps = {
  completed: false,
  current: false,
};

JobProgressBarStep.propTypes = {
  completed: bool,
  label: string.isRequired,
  current: bool,
};

const JobProgressBar = ({ className, stepData }) => {
  const { completedSteps, currentStep } = stepData;
  const classes = classNames(css.progressBarContainer, className);
  return (
    <div className={classes}>
      <div className={css.progressBar}>
        <div className={css.progressBarBase}></div>
        <div className={css.progressBarSteps}>
          {completedSteps.map(step => (
            <JobProgressBarStep
              key={step.key}
              completed={step.completed}
              label={`JobProgressBar.${step.key}`}
              current={step.key === currentStep}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

JobProgressBar.propTypes = { className: string, stepData: object.isRequired };

export default JobProgressBar;
