import React from 'react';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { arrayOf, string } from 'prop-types';
import classNames from 'classnames';
import { Avatar, UserDisplayName } from '../../components';
import { propTypes } from '../../util/types';

import css from './Proposals.module.css';
import { txState } from '../../containers/InboxPage/InboxPage';
import { txCandidateHasBeenShortlisted, txIsDeclined } from '../../util/transaction';

const Proposal = props => {
  const { proposal, onOpenProposal, isSelected, intl } = props;

  const classes = classNames(css.proposal, {
    [css.proposalActive]: isSelected,
  });

  const proposalState = txState(intl, proposal, 'sale');

  const statusClasses = classNames(css.proposalState, {
    [css.proposalStateShortlisted]:
      txCandidateHasBeenShortlisted(proposal) && !txIsDeclined(proposal),
  });

  return (
    <div className={classes} onClick={() => onOpenProposal(proposal)}>
      <Avatar className={css.avatar} user={proposal.customer} disableProfileLink={true} />
      <div className={css.proposalData}>
        <UserDisplayName user={proposal.customer} intl={intl} />
        <p className={statusClasses}>{proposalState?.state}</p>
      </div>
    </div>
  );
};

Proposal.propTypes = {
  // proposal: propTypes.proposal.isRequired,
  intl: intlShape.isRequired,
};

const ProposalsComponent = props => {
  const { className, rootClassName, proposals, onOpenProposal, openProposalId, intl } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <ul className={classes}>
      {proposals.map(r => {
        const isSelected = openProposalId === r.id.uuid;
        return (
          <li key={`Proposal_${r.id.uuid}`} className={css.proposalItem}>
            <Proposal
              proposal={r}
              onOpenProposal={onOpenProposal}
              isSelected={isSelected}
              intl={intl}
            />
          </li>
        );
      })}
    </ul>
  );
};

ProposalsComponent.defaultProps = {
  className: null,
  rootClassName: null,
  proposals: [],
};

ProposalsComponent.propTypes = {
  className: string,
  rootCalssName: string,
  proposals: arrayOf(propTypes.proposal),

  // from injectIntl
  intl: intlShape.isRequired,
};

const Proposals = injectIntl(ProposalsComponent);

export default Proposals;
