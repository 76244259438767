import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.module.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Last updated: April, 16th 2024</p>

      <p>
        These RecHunt Terms of Service (“<b>Terms</b>”) apply to using the internet marketplace offered by RecHunt Oy for matching hiring companies and recruitment service providers. By agreeing to use the Service, the user accepts these Terms and agrees to comply with them. The user and RecHunt Oy enter into agreement when the User registers to the Service.
      </p>

      <p>
        PLEASE  READ  THESE  TERMS  CAREFULLY  AS  THEY  CONTAIN  IMPORTANT  INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS.
      </p>

      <ol>
        <li>
          <h3>SERVICE PROVIDER</h3>

          <table>
            <tr>
              <td>Name</td>
              <td className={css.right}>RecHunt Oy</td>
            </tr>
            <tr>
              <td>Business ID</td>
              <td className={css.right}>3242212-8</td>
            </tr>
            <tr>
              <td>Address</td>
              <td className={css.right}>Eteläinen Hesperiankatu 16 B 33, 00100 Helsinki</td>
            </tr>
            <tr>
              <td>Email</td>
              <td className={css.right}>contact@rechunt.com</td>
            </tr>
            <tr>
              <td>Phone number</td>
              <td className={css.right}>+358 50 560 8881 (on business days at 9 – 16 GMT+2)</td>
            </tr>
          </table>

          <p>
            (Hereinafter “<b>RecHunt</b>”)
          </p>
        </li>

        <li>
          <h3>THE SERVICE</h3>

          <p>
            RecHunt maintains an internet marketplace to connect natural persons and entities providing headhunting and recruiting services (“<b>Recruiters</b>”) and entities seeking to engage a recruiting service to fulfil a position of employment (“<b>Hiring Companies</b>”) (together “<b>Users</b>”). The platform provided by RecHunt operates by means of the Hiring Companies posting recruitment opportunities (“<b>Open Jobs</b>”) to which the Recruiters can reply with proposals containing an offered price of their services and quotations of their services and expertise (“<b>Proposals</b>”). The Hiring Company selects a suitable Recruiter based on the Proposals to proceed with the recruitment or headhunting service. The Hiring Company and the selected Recruiter enter into a bilateral agreement concerning the assignment, where the Recruiter provides recruitment or headhunting service for the Hiring Company (“<b>Assignment</b>”). RecHunt is not a juridical party of the Assignment agreement, but merely provides the framework in which the Recruiters and Hiring Companies may negotiate the terms of the Assignment. RecHunt is not and does not become a party of the Assignment agreement between the Hiring Company and the Recruiter. The Hiring Company and the Recruiter are liable for their actions withing the scope of the Assignment agreement. RecHunt provides only a platform where Hiring Companies and Recruiters meet.
          </p>
          <p>
            The service provided by RecHunt consists of the internet platform and registration database the users can utilize to the abovementioned purposes (“<b>Service</b>”). Managing the framework of the Assignment shall be executed via the Service. The Service exists on the website in the address <a href="https://www.rechunt.com">www.rechunt.com</a>, on which website and its sub-sites the current content and scope of the Service is presented from time to time.
          </p>
          <p>
            The Users are not required to enter into any Assignment agreements. Using the Service does not constitute an obligation to place Open Jobs, Proposals or to enter Assignment agreements.
          </p>
        </li>

        <li>
          <h3>REGISTRATION AND USE</h3>

          <h3>3.1. Registration</h3>

          <p>
            Registration and creating a User Account is required for use of the Service. Upon registration and entering into agreement with RecHunt, the User is granted access to the Service for the term of the agreement. The User Account and the password and username concerning a User Account (“<b>User Account Information</b>”)are individual and specific to one person.
          </p>
          <p>
            A User shall create an individual User Account for each natural person acting in the name of an entity. The User Account Information shall not be shared with another person(s) and/or used by another person(s) to the natural person the User Account is created for. RecHunt is entitled to delete any User Accounts it suspects to be misused or whose User Account Information appears to be compromised. RecHunt is entitled to prohibit use of a User Account indefinitely or for a limited time if the User breaches these Terms or otherwise acts in bad faith.
          </p>
          <p>
            To be able to post Open Jobs, a Hiring Company wishing to recruit personnel must register their representative as a User of the Service. Upon registration, a Hiring Company representative shall create a profile defining the basic information of the Hiring Company entity, contact information of the entity and contact details of the representative of the Hiring Company, and other details required by RecHunt from time to time relating to the Hiring Company entity or the Hiring Company representative. All the Hiring Company’s User Information remain hidden until they in connection with their Open Job have listed one or several suitable Proposals to continue negotiations about (“<b>Proposal Shortlist</b>”). After being approved to the Proposal Shortlist, the respective Recruiter gains access to the Hiring Company’s basic details including the entity name, description, industry, location, website, number of employees, and the representatives contact information (“<b>Hiring Company Details</b>”). The Hiring Company Details are not visible to other Users and all information concerning the Hiring Company cease to be visible to a Recruiter if such Recruiter is refused from continuing negotiations for a recruitment Assignment or deleted from the Proposal Shortlist.
          </p>
          <p>
            To be able to access the database of Open Jobs and to place Proposals, a Recruiter must register as a User of the Service. Upon registration, a Recruiter shall create a profile defining their contact information, experience, and other relevant information such as details of the organization they represent and their personal work experience. Recruiters may be required to provide other information. The Recruiter details shall be forwarded to the Hiring Company when the Recruiter sends a Proposal to such Hiring Company.
          </p>
          <p>
            RecHunt is entitled to checking the User’s public financial and credit records and to prohibit the use of the Service, if the User is insolvent, has multiple bad credit records or is not registered in the preliminary tax withholding register or VAT register (if applicable regarding the User). Despite the above-mentioned rights, RecHunt does not give any guarantees regarding the financial stability, business, or the quality of the Users. Each User is liable for checking and verifying the financial and other information of other Users before entering into Assignment agreements with such Users. RecHunt is not liable for verifying any information submitted to the Service by the Users.
          </p>

          <h3>3.2. Terms of Use</h3>

          <p>
            RecHunt hereby grants the Users a personal, non-exclusive, non-transferable right to use the Service within its customary scope of operation.
          </p>
          <p>
            In case RecHunt detects use in breach of these Terms or the applicable legislation, RecHunt has the right to restrict or prohibit the use of the Service without prior notification to the defecting User or to undertake any other actions RecHunt deems as necessary.
          </p>
          <p>
            By registering to the Service, the User agrees and undertakes, that
          </p>

          <ol type="a">
            <li>
              <p>
                all information provided by the User is current, true, accurate and complete and in compliance with applicable laws and regulations
              </p>
            </li>
            <li>
              <p>
                the User registering to the Service is 18 years old or older and with legal capacity. Any access to the website the Service is provided on, or use of the Service, is expressly prohibited from any underage persons or persons without legal capacity.
              </p>
            </li>
            <li>
              <p>
                when representing a company or other entity, the User is duly authorized to representing such company or entity and to making decisions regarding recruiting personnel to such company or entity
              </p>
            </li>
            <li>
              <p>
                when representing a company or an entity, the company or entity is not insolvent and
              </p>
            </li>
            <li>
              <p>
                the User does not have a previous, valid account in the Service.
              </p>
            </li>
          </ol>

          <p>
            The User agrees and undertakes, that all Open Jobs or Proposals posted to the Service are real and appropriate. Posting invalid, misleading, illegal or inappropriate Open Jobs or Proposals may lead to erasing such Open Job or Proposal or to deleting such User making such posts to the Service.
          </p>
          <p>
            The User agrees and undertakes to amend, update or erase information as necessary or on the request of RecHunt. RecHunt has the right to erase, amend, restrict access to, or rectify User provided information without the User’s consent on weighty reason or if the User breaches these Terms.
          </p>
          <p>
            A User must not use or try to use another User’s account without said other User’s permission. Any copying, amending, or creating derivative works of the Service or the technology forming the Service is prohibited. The User shall not decode or otherwise attempt to learn the source code of the Service nor delete any labels or marks indicating intellectual property rights. The User  shall  not  register  to  the  Service  using  incorrect  or  untrue  information  or  use  another person’s personal information. Transferring the User Information or the right to use the Service to a third party or selling, transferring, or reselling the Service without RecHunt’s prior written consent is prohibited.
          </p>

          <h3>3.3. User Representations and Warranties</h3>

          <p>
            The User represents and warrants, that
          </p>

          <ol type="a">
            <li>
              <p>
                the User is 18 years old or older and with legal capacity and fully legally competent to enter into binding agreements on their own behalf or, when representing a company or an entity, on the company’s or entity’s behalf, and the company or entity represented is not insolvent
              </p>
            </li>
            <li>
              <p>
                the User always complies with these Terms and with all applicable laws and regulations governing its business, including but not limited to value added tax liabilities and other tax and liability related liabilities, Data Privacy and Data Security, as well as obligations under Consumer Protection Act, if applicable
              </p>
            </li>
            <li>
              <p>
                the User maintains appropriate Trade Register entries and answers for all obligations and fees under public law relating to its business, including but not limited to the User’s legal obligations as a Hiring Company and the registration to the preliminary tax withholding register, if applicable
              </p>
            </li>
            <li>
              <p>
                the User fulfils their contractual obligations concerning Assignment agreements and always includes sufficient and truthful information in its communications with other Users within the scope of the Service and strives for answering any contact request within reasonable time
              </p>
            </li>
            <li>
              <p>
                the User uses the Service in compliance with good business practice, has appropriate data connections, data terminal devices and programs to use the Service and does not use the Service in bad faith or for criminal or illegal purposes.
              </p>
            </li>
          </ol>
        </li>

        <li>
          <h3>TOKENS</h3>

          <p>
            The Recruiters use credits granted by RecHunt or purchased from the Service to pay for different costs arising from the use of the Service (“<b>Tokens</b>”).
          </p>
          <p>
            The Recruiter Users are granted five (5) Tokens upon registration free of cost. The free Tokens granted by registration can be granted only once per a User. Additional Tokens can be purchased within the Service with prices applicable at a time. RecHunt might grant additional free Tokens for instance as a part of campaigns.
          </p>
          <p>
            The Tokens cost EUR 40 each. Bundle offers, where the calculatory price per one Token is lower, may be offered by RecHunt. A User can purchase an unlimited number of Tokens. Tokens are valid for one year from the purchase date and will be removed from  the  account  if  not  used  during  the  one  year  period. RecHunt is entitled to amending the Token price at its sole discretion.
          </p>
          <p>
            Each Token can be used to make one Proposal to the Service. The Hiring Company actions in the Service such as posting Open Jobs, shortlisting Proposals and choosing Recruiters to negotiate the Assignment with is free of cost.
          </p>
          <p>
            Used Tokens are not reimbursed or otherwise returned if the Recruiter’s Proposal is declined or, after the Recruiter has been approved to the Shortlist, the Hiring Company does not enter into Assignment agreement with such Recruiter. Any unused Tokens are not reimbursed in case the Recruiter chooses to discontinue using the Service.
          </p>
          <p>
            RecHunt may change or amend the conditions under which Tokens are granted and used as well as the price of a Token. Further details regarding pricing and invoicing are provided in the Chapter 7 of these Terms.
          </p>
        </li>

        <li>
          <h3>OPEN JOBS</h3>

          <p>
            Posting an Open Job to the Service is free of cost. Posting the Open Job takes place by the Hiring Company filling in a profiling form of the Open Job and submitting it to the Service. In the profiling form, initial details of the Open Job are provided to describe the need for recruitment. The details required for the Open Job include but are not limited to the title of the position, industry and job category, number of open positions, general description of the tasks and responsibilities, location, office hours and working time, expected starting date, and the overall requirements for a suitable candidate. The Hiring Company may present a preliminary salary range. The Hiring Company shall not place any requirements to the pricing of the Recruiters’ services.
          </p>
          <p>
            After completing the profiling form, a new Open Job is created to the Service. The Hiring Company details remain anonyme at this point. The Hiring Company details shall be revealed to the Recruiters, if the Hiring Company chooses their Proposals to its shortlist in order to continue negotiations with them. After the Hiring Company has chosen one Recruiter to complete the Assignment with, the Open Job becomes visible only to the parties of the Assignment. After completion of the recruitment process, the Open Job is archived and no longer visible to other parties than the parties of the final recruitment Assignment.
          </p>
        </li>

        <li>
          <h3>PROPOSALS AND RECRUITING</h3>

          <p>
            The Recruiters may send Proposals to Open Jobs. Using Tokens defined in Chapter 4 (Tokens) of the Terms, the Recruiter makes Proposals to the Open Jobs described in Chapter 5 (Open Jobs). The Recruiter shall be responsible for pricing and otherwise defining their services and complying with the marginal terms set in the respective Open Job. RecHunt does not provide consultation on compiling Proposals other than providing the structured form in using which the Proposals shall be compiled.
          </p>
          <p>
            After receiving Proposals, the Hiring Company shall select at maximum four (4) most suitable Recruiters to its shortlist to continue the negotiations with (“<b>Selected Recruiters</b>”). After selection, the Selected Recruiters shall have access to the Hiring Company Details.
          </p>
          <p>
            Following selection of the Recruiters, the Hiring Company and the Selected Recruiters shall continue discussions either in the Service or using the contact details provided in their User profile and after negotiations and eventual meetings, the Hiring Company shall select one (1) Recruiter to engage in the recruitment. If the Parties agree on amendments to the Proposal, these shall be written in a separate agreement and provided to RecHunt if requested. However, RecHunt must be immediately notified of any agreed amendments to the pricing of the Assignment. The Hiring Company shall expressly indicate their approval of the proposal, and these Terms (“<b>Phase 1</b>”). The Selected Recruiter shall expressly indicate their approval of the Assignment, these Terms and accepting the invoice from RecHunt for the commission.
          </p>
          <p>
            After completion of the recruitment process, the Hiring Company shall report to the Service when/if the assignment has been completed.
          </p>
          <p>
            After completion of the recruitment process, the Hiring Company may provide a review of the Recruiter. The Hiring Company undertakes only to review such Recruiters they have used in an Assignment. RecHunt may erase such reviews that contain inappropriate language or are otherwise impertinent. The Recruiter reviews are visible to all Users and shown on the Recruiter profile.
          </p>
          <p>
            After completion of the recruitment process, the Recruiter may provide a review of the Hiring Company. RecHunt may erase such reviews that contain inappropriate language or are otherwise impertinent. The Hiring Company reviews are visible only to the Hiring Company in question.
          </p>
          <p>
            The User is fully responsible for any and all information provided in and relating to an Open Job or a Proposal made available, published or sent within the Service. Both the Hiring Company and the Recruiter are liable for reporting the progress of the recruiting process to the Service. RecHunt is entitled to the Penalty described in Chapter 12 (Penalty) of these Terms in case the Users do not report the progress of the recruitment process to the Service in a timely manner.
          </p>
        </li>

        <li>
          <h3>PRICING AND INVOICING</h3>

          <p>
            Registering as a User of the Service and browsing Open Jobs as a registered User is free of cost.
          </p>
          <p>
            Placing Proposals is paid using Tokens. Each activity subject to charge in the Service costs 1 Token. The Recruiters are responsible for pricing their services in relation to the Proposals they place within the Service. All invoicing based on the Proposal shall be executed by the Recruiter. and the Parties agree upon the payment terms.
          </p>

          <p>
            RecHunt is entitled to a commission of 4% of the total project price, which is invoiced in full after Phase 1 is complete.
          </p>
          <p>
            The prices shall be expressed in euros (EUR). If not expressly otherwise stated, the prices do not include applicable VAT or other taxes, and such taxes are added to the published prices in accordance with the applicable tax laws and regulations.
          </p>
          <p>
            RecHunt is not liable for any delay or missing payments.
          </p>
          <p>
            In case the Selected Recruiter does not perform its services in accordance with the Assignment terms, the Hiring Company is entitled to all legal remedies at its disposal against the Recruiter. For avoidance of doubt, as RecHunt is not a party of the Assignment, RecHunt is not in any way liable for defects in execution of the Assignment.
          </p>
          <p>
            RecHunt is not liable for any damages occurring to the Recruiter from the delayed or missing payments that result from the Hiring Company withholding a payment, insolvency of the Hiring Company, or any other defect or delay resulting from the actions of the Hiring Company.
          </p>
          <p>
            RecHunt is entitled to make amendments to the prices on its sole discretion. The amended prices become effective after one (1) month of RecHunt has made the price amendments known with a written notice on its website.
          </p>
        </li>

        <li>
          <h3>RECRUITMENT PROCESS, GUARANTEE AND RECURRING CO-OPERATION</h3>

          <p>
            The Hiring Company may withdraw from the process with no additional cost to them before choosing one Selected Recruiter, i.e. if no suitable Recruiters are found concerning an Open Job.
          </p>

          <p>
            The Recruiter may, at their discretion, provide a guarantee to their work on the Assignment. The scope and duration of the guarantee is at the sole discretion of the Recruiter. RecHunt is not liable for any promises, guarantees or warranties the Recruiter has given regarding its services.
          </p>
          <p>
            The Users agree that after entering their first Assignment with a new party through the Service, they agree and undertake to use the Service as their sole platform for purchasing and providing recruitment services in relation to this new counterparty. The Users agree and undertake, that during this Non-Circumvention Period they will use the Service as the exclusive method of cooperating and facilitating the Assignments with such counterparty and they will not circumvent the payment methods offered in the Service. This Non-Circumvention Period is valid for 24 months from the start of each Assignment with a new party, i.e. from the date when an Hiring Company approves a Selected Recruiter they have not entered into an Assignment agreement via the Service before.
          </p>
        </li>

        <li>
          <h3>UPDATES, MAINTENANCE AND CHANGES TO THE SERVICE</h3>

          <p>
            RecHunt can, in its sole discretion, make any changes to the Service. RecHunt notifies the Users prior to making changes to the Service, in which case the changes come into effect at the time stated in such notification. If the time of taking effect is not separately determined, changes made to the Service become effective at the time of making such change. Before significant changes to the Service, RecHunt aims to notify the Users one (1) month prior to the effective date.
          </p>
          <p>
            RecHunt is entitled to suspend or restrict the use of the Service for a limited time in order to perform any necessary maintenance work or updates to the Service. RecHunt is not liable for any losses or damages incurred of such suspension or restriction. RecHunt shall notify the Users of such anticipated suspension or restriction as it deems appropriate in each case.
          </p>
          <p>
            RecHunt strives for providing a fault-free Service. RecHunt is however not liable for any damages arising of or relating to a malfunction of the Service. RecHunt is not liable for the non-compliance of the Service with the devices, internet providers or connections, software, or other technical means of the User via which the Service is used.
          </p>
        </li>

        <li>
          <h3>IMMATERIAL PROPERTY RIGHTS</h3>

          <p>
            The Service and its contents, as amended from time to time, are copyrighted works belonging to RecHunt or possible licensors. The Service might contain other immaterial property rights including but not limited to trademarks, patents and trade secrets, which also belong to RecHunt or its possible licensors. These Terms do not constitute a transfer of any rights, title or interest to the Service from RecHunt or its licensors to the Users.
          </p>
          <p>
            All Users or their licensors shall have and retain the full right, title and interest to their CV’s, photos, and all information (“<b>Material</b>”) they save to their user profiles. No rights of ownership to such material shall be transferred to RecHunt or to other Users. The User is responsible for ensuring their right to add Material to the Service and of that the Material does not infringe the rights of a third party and is not illegal or inappropriate. RecHunt is entitled to imposing detailed requirements for the type of Material the User is required or allowed to upload.
          </p>
          <p>
            RecHunt is entitled to use the Material in order to provide services to the Users, to further develop the Service, or to other reasonable business objectives, assuming that the privacy of any User is not compromised.
          </p>
          <p>
            In case a User detects a clear infringement of their immaterial property rights within the Service, the User is entitled to ask RecHunt to investigate the matter and delete the infringing material. RecHunt is not responsible to act upon any infringements and does not take part in any disputes concerning the use of the Material uploaded to the Service. RecHunt does not provide any legal counselling or act as a representative of a User. Only a clear, undisputable infringement can lead to RecHunt deleting infringing material upon a request of the offended User.
          </p>
        </li>

        <li>
          <h3>DATA PRIVACY AND DATA SECURITY</h3>

          <p>
            RecHunt act as a Controller of the data that is uploaded by the User into the Service (“<b>User Data</b>”). RecHunt undertakes to comply with the applicable laws and regulations and governs and processes the User Data in accordance with the privacy statement valid at the time. The RecHunt Privacy Statement can be found here: <a href="https://rechunt.com/privacy-policy">www.rechunt.com/privacy-policy</a>. The Privacy Statement constitutes an inseparable part of these Terms.
          </p>
          <p>
            RecHunt undertakes to store and protect the User Data in compliance with the standard data security practices customary to its business.
          </p>
          <p>
            In case a User can be deemed to be a Controller or Processor of Data in accordance with the General Data Protection Regulation and other applicable laws and regulations, the User undertakes to comply with applicable requirements specified in the laws and regulations regarding collecting, storing and processing the data and to fulfil all obligations set forth to a controller or processor of data.
          </p>
        </li>

        <li>
          <h3>PENALTY</h3>

          <p>
            RecHunt  has  the  right  to  a  penalty  of  EUR  10,000.00  if  a  Hiring  Company  finds  a  suitable Recruiter via the Service, and proceeds to agreeing with such Recruiter outside the Service, or if the Users fail to report the progress of the recruitment process in accordance with Chapter 6 of these Terms.
          </p>
        </li>

        <li>
          <h3>RECLAMATION AND LIABILITY</h3>

          <p>
            RecHunt is not liable for any damages arising of or relating to the use of the Service. RecHunt is merely a provider for the platform and is not responsible for any actions the Users engage in by using the Service. Although RecHunt strives for providing a fault free Service, the Service is offered to use “as is” and RecHunt gives no guarantees on the fault free or uninterrupted use of the Service. RecHunt is not liable for any breach of these Terms under a Force Majeure as defined in Finnish law.
          </p>
          <p>
            RecHunt  is  not  responsible  for  any  information  the  Users  upload  to  the  Service  and  as RecHunt does not check such information prior to publishing, the Service may contain invalid, incomplete, illegal, misleading, or inappropriate information from time to time. RecHunt is not liable  for  any  damages  arising  out  or  relating  to  any  misleading,  faulty,  inappropriate,  or inaccurate  information  provided  by  the  Users.  RecHunt  is  not  responsible  for  amending, correcting, or erasing such information. RecHunt is, however, entitled to on its own discretion to amend, correct, erase or rectify any information submitted by the Users to the Service.
          </p>
          <p>
            The  User  is  liable  for  compensating  RecHunt  for  all  damages  that  incur  from  purposely  or negligently breaching these Terms and for any loss of goodwill arising from the inappropriate or unlawful conduct of the User. RecHunt is entitled to apply any funds in its disposal, that the User is entitled to, against any liabilities the User owns to RecHunt.
          </p>
          <p>
            All  reclamations,  claims,  notifications,  and  complaints  concerning  the  Assignment  must  be addressed to the appropriate party of the Assignment agreement. RecHunt is not a party of the Assignment agreement. The User must note that all reclamations, claims, notifications and complaints concerning a breach of contract or a defect in fulfilling contractual obligations must be brought to the attention of the defaulting party within a reasonable time after detection of such breach or defect.
          </p>
        </li>

        <li>
          <h3>INDEMNITY</h3>

          <p>
            The User indemnifies RecHunt and its personnel from any and all claims and demands made against RecHunt due to the conduct or other agreements of the User or the Material the User has uploaded to the Service.
          </p>
        </li>

        <li>
          <h3>ASSIGNMENT</h3>

          <p>
            RecHunt has the right to transfer the agreement with a User in part or as full and its rights and obligations arising of these Terms to a third party at any time without a prior consent of a User. RecHunt has the right of using subcontractors to fulfil its obligations under these Terms.
          </p>
          <p>
            A User has no right to assign its agreement with RecHunt or the rights and obligations arising thereof to a third party without a prior written consent of RecHunt.
          </p>
        </li>

        <li>
          <h3>AMENDING THE TERMS</h3>

          <p>
            RecHunt shall be entitled to amending these Terms unilaterally and without prior consent of the Users. RecHunt shall notify the Users of amendments to the Terms within a reasonable time before the amended Terms shall become applicable.
          </p>
          <p>
            RecHunt  is  entitled  to  amending  the  terms  of  Pricing  and  amount  of  Commission.  These changes are up to written consent of the User. If the User does not accept the amended Pricing and Commission terms, the User is entitled to discontinue use of the Service with no additional costs occurring to them. RecHunt shall be entitled to invoicing all outstanding amounts due 14 days after the User has discontinued use of the Service.
          </p>
        </li>

        <li>
          <h3>GOVERNING LAW AND DISPUTE REVOLUTION</h3>

          <p>
            These Terms are governed by Finnish law.
          </p>
          <p>
            Any dispute, controversy or claim arising of or relating to the Terms, which cannot be settled amicably, shall be finally settled in Helsinki District Court as the first instance.
          </p>
          <p>
            The above-mentioned notwithstanding, in case the User is a consumer in the meaning of the Finnish  Consumer  Protection  Act  (38/1978  as  amended)  (“<b>Consumer  User</b>”),  they  have  a right to sue RecHunt in the appropriate District Court of their domicile or permanent residence in  Finland.  Finnish  Consumer  Users  may  also  consult  the  Consumer  Disputes  Board.  All Consumer  Users  may  use  the  dispute  resolution  service  provided  by  the  European Commission at http://ec.europa.eu/odr.
          </p>
        </li>

        <li>
          <h3>EFFECTIVE PERIOD</h3>

          <p>
            These  Terms  shall  become  effective  when  a  natural  person  or  a  company’s  or  entity’s representative registers themselves as a User of the Service and continue to be effective for the time the User uses the Service.
          </p>
          <p>
            The contractual relationship shall terminate, and these Terms cease to apply when the User stops  using  the  Service  and  deletes  their  user  account  or  when  RecHunt  terminates  the Service. RecHunt is entitled to terminate the Service partly or completely at any time.
          </p>
        </li>
      </ol>
    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
