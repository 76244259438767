import { ensureTransaction } from './data';

/**
 * Transitions
 *
 * These strings must sync with values defined in Flex API,
 * since transaction objects given by API contain info about last transitions.
 * All the actions in API side happen in transitions,
 * so we need to understand what those strings mean.
 */

// When a customer makes an order for a listing, a transaction is
// created with the initial request-payment transition.
// At this transition a PaymentIntent is created by Marketplace API.
// After this transition, the actual payment must be made on client-side directly to Stripe.
export const TRANSITION_REQUEST_PAYMENT = 'transition/request-payment';

// A customer can also initiate a transaction with an enquiry, and
// then transition that with a request.
// export const TRANSITION_PROPOSE = 'transition/propose';
export const TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY = 'transition/request-payment-after-enquiry';

// Stripe SDK might need to ask 3D security from customer, in a separate front-end step.
// Therefore we need to make another transition to Marketplace API,
// to tell that the payment is confirmed.
export const TRANSITION_CONFIRM_PAYMENT = 'transition/confirm-payment';

// RecHunt related transitions
export const TRANSITION_PROPOSE = 'transition/propose';
export const TRANSITION_DECLINE_PROPOSAL = 'transition/decline-proposal';
export const TRANSITION_DECLINE_PROPOSAL_BY_OPERATOR = 'transition/decline-proposal-by-operator';
export const TRANSITION_WITHDRAW_PROPOSAL = 'transition/withdraw-proposal';
export const TRANSITION_SHORTLIST_RECRUITER = 'transition/shortlist-recruiter';
export const TRANSITION_DECLINE_FROM_SHORTLIST_BY_COMPANY =
  'transition/decline-from-shortlist-by-company';
export const TRANSITION_DECLINE_FROM_SHORTLIST_BY_OPERATOR =
  'transition/decline-from-shortlist-by-operator';
export const TRANSITION_DECLINE_FROM_SHORTLIST_BY_RECRUITER =
  'transition/decline-from-shortlist-by-recruiter';
export const TRANSITION_SELECT_RECRUITER = 'transition/select-recruiter';
export const TRANSITION_ACCEPT_OFFER = 'transition/accept-offer';
export const TRANSITION_DECLINE_OFFER = 'transition/decline-offer';
export const TRANSITION_SIGN_EMPLOYMENT_AGREEMENT = 'transition/sign-employment-agreement';
export const TRANSITION_MARK_RECRUITMENT_COMPLETED_BY_RECRUITER =
  'transition/mark-recruitment-completed-by-recruiter';
export const TRANSITION_SIGN_EMPLOYMENT_AGREEMENT_NOT_COMPLETED =
  'transition/sign-employment-agreement-not-completed';
export const TRANSITION_MARK_RECRUITMENT_UNCOMPLETED_BY_RECRUITER =
  'transition/mark-recruitment-uncompleted-by-recruiter';

// System moves transaction automatically from received state to complete state
// This makes it possible to to add notifications to that single transition.
export const TRANSITION_AUTO_COMPLETE = 'transition/auto-complete';

// Reviews are given through transaction transitions. Review 1 can be
// by provider or customer, and review 2 will be the other party of
// the transaction.
export const TRANSITION_REVIEW_1_BY_PROVIDER = 'transition/review-1-by-provider';
export const TRANSITION_REVIEW_2_BY_PROVIDER = 'transition/review-2-by-provider';
export const TRANSITION_REVIEW_1_BY_CUSTOMER = 'transition/review-1-by-customer';
export const TRANSITION_REVIEW_2_BY_CUSTOMER = 'transition/review-2-by-customer';
export const TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD = 'transition/expire-customer-review-period';
export const TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD = 'transition/expire-provider-review-period';
export const TRANSITION_EXPIRE_REVIEW_PERIOD = 'transition/expire-review-period';

/**
 * Actors
 *
 * There are 4 different actors that might initiate transitions:
 */

// Roles of actors that perform transaction transitions
export const TX_TRANSITION_ACTOR_CUSTOMER = 'customer';
export const TX_TRANSITION_ACTOR_PROVIDER = 'provider';
export const TX_TRANSITION_ACTOR_SYSTEM = 'system';
export const TX_TRANSITION_ACTOR_OPERATOR = 'operator';

export const TX_TRANSITION_ACTORS = [
  TX_TRANSITION_ACTOR_CUSTOMER,
  TX_TRANSITION_ACTOR_PROVIDER,
  TX_TRANSITION_ACTOR_SYSTEM,
  TX_TRANSITION_ACTOR_OPERATOR,
];

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
const STATE_INITIAL = 'initial';
const STATE_PROPOSED = 'proposed';
const STATE_RECRUITER_SHORTLISTED = 'recruiter-shortlisted';
const STATE_RECRUITER_SELECTED = 'recruiter-selected';
const STATE_CANDIDATES_SHORTLIST_ACCEPTED = 'candidates-shortlist-accepted';
const STATE_CANDIDATE_HIRED = 'candidate-hired';
const STATE_REVIWED = 'reviews';
const STATE_DECLINED = 'declined';
const STATE_REVIEWED_BY_CUSTOMER = 'reviewed-by-customer';
const STATE_REVIEWED_BY_PROVIDER = 'reviewed-by-provider';

/**
 * Description of transaction process
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
const stateDescription = {
  // id is defined only to support Xstate format.
  // However if you have multiple transaction processes defined,
  // it is best to keep them in sync with transaction process aliases.
  id: 'rechunt-default-process/release-1',

  // This 'initial' state is a starting point for new transaction
  initial: STATE_INITIAL,

  // States
  states: {
    [STATE_INITIAL]: {
      on: {
        [TRANSITION_PROPOSE]: STATE_PROPOSED,
      },
    },
    [STATE_PROPOSED]: {
      on: {
        [TRANSITION_SHORTLIST_RECRUITER]: STATE_RECRUITER_SHORTLISTED,
        [TRANSITION_DECLINE_PROPOSAL]: STATE_DECLINED,
        [TRANSITION_DECLINE_PROPOSAL_BY_OPERATOR]: STATE_DECLINED,
        [TRANSITION_WITHDRAW_PROPOSAL]: STATE_DECLINED,
      },
    },
    [STATE_RECRUITER_SHORTLISTED]: {
      on: {
        [TRANSITION_SELECT_RECRUITER]: STATE_RECRUITER_SELECTED,
        [TRANSITION_DECLINE_FROM_SHORTLIST_BY_COMPANY]: STATE_DECLINED,
        [TRANSITION_DECLINE_FROM_SHORTLIST_BY_OPERATOR]: STATE_DECLINED,
        [TRANSITION_DECLINE_FROM_SHORTLIST_BY_RECRUITER]: STATE_DECLINED,
      },
    },

    [STATE_RECRUITER_SELECTED]: {
      on: {
        [TRANSITION_ACCEPT_OFFER]: STATE_CANDIDATES_SHORTLIST_ACCEPTED,
        [TRANSITION_DECLINE_OFFER]: STATE_DECLINED,
      },
    },

    [STATE_CANDIDATES_SHORTLIST_ACCEPTED]: {
      on: {
        [TRANSITION_SIGN_EMPLOYMENT_AGREEMENT]: STATE_CANDIDATE_HIRED,
        [TRANSITION_SIGN_EMPLOYMENT_AGREEMENT_NOT_COMPLETED]: STATE_CANDIDATE_HIRED,
        [TRANSITION_MARK_RECRUITMENT_COMPLETED_BY_RECRUITER]: STATE_CANDIDATE_HIRED,
        [TRANSITION_MARK_RECRUITMENT_UNCOMPLETED_BY_RECRUITER]: STATE_CANDIDATE_HIRED,
      },
    },

    [STATE_DECLINED]: {},

    [STATE_CANDIDATE_HIRED]: {
      on: {
        [TRANSITION_EXPIRE_REVIEW_PERIOD]: STATE_REVIWED,
        [TRANSITION_REVIEW_1_BY_CUSTOMER]: STATE_REVIEWED_BY_CUSTOMER,
        [TRANSITION_REVIEW_1_BY_PROVIDER]: STATE_REVIEWED_BY_PROVIDER,
      },
    },

    [STATE_REVIEWED_BY_CUSTOMER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_PROVIDER]: STATE_REVIWED,
        [TRANSITION_EXPIRE_PROVIDER_REVIEW_PERIOD]: STATE_REVIWED,
      },
    },
    [STATE_REVIEWED_BY_PROVIDER]: {
      on: {
        [TRANSITION_REVIEW_2_BY_CUSTOMER]: STATE_REVIWED,
        [TRANSITION_EXPIRE_CUSTOMER_REVIEW_PERIOD]: STATE_REVIWED,
      },
    },

    [STATE_REVIWED]: { type: 'final' },
  },
};

// Note: currently we assume that state description doesn't contain nested states.
const statesFromStateDescription = description => description.states || {};

// Get all the transitions from states object in an array
const getTransitions = states => {
  const stateNames = Object.keys(states);

  const transitionsReducer = (transitionArray, name) => {
    const stateTransitions = states[name] && states[name].on;
    const transitionKeys = stateTransitions ? Object.keys(stateTransitions) : [];
    return [
      ...transitionArray,
      ...transitionKeys.map(key => ({ key, value: stateTransitions[key] })),
    ];
  };

  return stateNames.reduce(transitionsReducer, []);
};

// This is a list of all the transitions that this app should be able to handle.
export const TRANSITIONS = getTransitions(statesFromStateDescription(stateDescription)).map(
  t => t.key
);

// This function returns a function that has given stateDesc in scope chain.
const getTransitionsToStateFn = stateDesc => state =>
  getTransitions(statesFromStateDescription(stateDesc))
    .filter(t => t.value === state)
    .map(t => t.key);

// Get all the transitions that lead to specified state.
const getTransitionsToState = getTransitionsToStateFn(stateDescription);

// This is needed to fetch transactions that need response from provider.
// I.e. transactions which provider needs to accept or decline
export const transitionsToProposed = getTransitionsToState(STATE_PROPOSED);

/**
 * Helper functions to figure out if transaction is in a specific state.
 * State is based on lastTransition given by transaction object and state description.
 */

const txLastTransition = tx => ensureTransaction(tx).attributes.lastTransition;

export const txIsProposed = tx =>
  getTransitionsToState(STATE_PROPOSED).includes(txLastTransition(tx));

export const txIsRecruiterShortlisted = tx =>
  getTransitionsToState(STATE_RECRUITER_SHORTLISTED).includes(txLastTransition(tx));

export const txIsRecruiterSelected = tx =>
  getTransitionsToState(STATE_RECRUITER_SELECTED).includes(txLastTransition(tx));

export const txIsCandidatesShortlistAccepted = tx =>
  getTransitionsToState(STATE_CANDIDATES_SHORTLIST_ACCEPTED).includes(txLastTransition(tx));

export const txIsCandidateHired = tx =>
  getTransitionsToState(STATE_CANDIDATE_HIRED).includes(txLastTransition(tx));

export const txIsDeclined = tx =>
  getTransitionsToState(STATE_DECLINED).includes(txLastTransition(tx));

export const txIsReviewedByCustomer = tx =>
  getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx));

export const txIsReviewedByProvider = tx =>
  getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

const firstReviewTransitions = [
  ...getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER),
  ...getTransitionsToState(STATE_REVIEWED_BY_PROVIDER),
];
export const txIsInFirstReview = tx => firstReviewTransitions.includes(txLastTransition(tx));

export const txIsInFirstReviewBy = (tx, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(txLastTransition(tx))
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(txLastTransition(tx));

export const txIsReviewed = tx =>
  getTransitionsToState(STATE_REVIWED).includes(txLastTransition(tx));

/**
 * Helper functions to figure out if transaction has passed a given state.
 * This is based on transitions history given by transaction object.
 */

const txTransitions = tx => ensureTransaction(tx).attributes.transitions || [];
const hasPassedTransition = (transitionName, tx) =>
  !!txTransitions(tx).find(t => t.transition === transitionName);

const hasPassedStateFn = state => tx =>
  getTransitionsToState(state).filter(t => hasPassedTransition(t, tx)).length > 0;

// Helper function to check if the transaction has passed a certain state
export const txCandidateHasBeenShortlisted = hasPassedStateFn(STATE_RECRUITER_SHORTLISTED);
export const txCandidateHasBeenHired = hasPassedStateFn(STATE_CANDIDATE_HIRED);

/**
 * Other transaction related utility functions
 */

export const transitionIsReviewed = transition =>
  getTransitionsToState(STATE_REVIWED).includes(transition);

export const transitionIsFirstReviewedBy = (transition, isCustomer) =>
  isCustomer
    ? getTransitionsToState(STATE_REVIEWED_BY_CUSTOMER).includes(transition)
    : getTransitionsToState(STATE_REVIEWED_BY_PROVIDER).includes(transition);

export const getReview1Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_1_BY_CUSTOMER : TRANSITION_REVIEW_1_BY_PROVIDER;

export const getReview2Transition = isCustomer =>
  isCustomer ? TRANSITION_REVIEW_2_BY_CUSTOMER : TRANSITION_REVIEW_2_BY_PROVIDER;

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
  return [
    TRANSITION_PROPOSE,
    TRANSITION_DECLINE_PROPOSAL,
    TRANSITION_WITHDRAW_PROPOSAL,
    TRANSITION_SHORTLIST_RECRUITER,
    TRANSITION_DECLINE_FROM_SHORTLIST_BY_COMPANY,
    TRANSITION_DECLINE_FROM_SHORTLIST_BY_RECRUITER,
    TRANSITION_SELECT_RECRUITER,
    TRANSITION_ACCEPT_OFFER,
    TRANSITION_DECLINE_OFFER,
    TRANSITION_SIGN_EMPLOYMENT_AGREEMENT,
    TRANSITION_REVIEW_1_BY_CUSTOMER,
    TRANSITION_REVIEW_1_BY_PROVIDER,
    TRANSITION_REVIEW_2_BY_CUSTOMER,
    TRANSITION_REVIEW_2_BY_PROVIDER,
  ].includes(transition);
};

export const isCustomerReview = transition => {
  return [TRANSITION_REVIEW_1_BY_CUSTOMER, TRANSITION_REVIEW_2_BY_CUSTOMER].includes(transition);
};

export const isProviderReview = transition => {
  return [TRANSITION_REVIEW_1_BY_PROVIDER, TRANSITION_REVIEW_2_BY_PROVIDER].includes(transition);
};

export const getUserTxRole = (currentUserId, transaction) => {
  const tx = ensureTransaction(transaction);
  const customer = tx.customer;
  if (currentUserId && currentUserId.uuid && tx.id && customer.id) {
    // user can be either customer or provider
    return currentUserId.uuid === customer.id.uuid
      ? TX_TRANSITION_ACTOR_CUSTOMER
      : TX_TRANSITION_ACTOR_PROVIDER;
  } else {
    throw new Error(`Parameters for "userIsCustomer" function were wrong.
      currentUserId: ${currentUserId}, transaction: ${transaction}`);
  }
};

export const txRoleIsProvider = userRole => userRole === TX_TRANSITION_ACTOR_PROVIDER;
export const txRoleIsCustomer = userRole => userRole === TX_TRANSITION_ACTOR_CUSTOMER;

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
  return [].includes(transition);
};
